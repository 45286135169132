// Global Style
import "./src/style/global/_reboot.scss"
import "./src/style/global/_utilities.scss"
// Vendors
import "splitting/dist/splitting.css"
import "splitting/dist/splitting-cells.css"

// // Vendors
// import "./node_modules/locomotive-scroll/dist/locomotive-scroll.min.css"

// Layout to use page transition
// import React from "react"
// import LayoutWrapper from "./src/layouts/wrapper"

// export const wrapPageElement = ({ element, props }) => {
//   return <LayoutWrapper {...props}>{element}</LayoutWrapper>
// }
