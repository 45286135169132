import React, { useEffect, useState } from "react"
import classnames from "classnames/bind"
import TransitionLink, { TransitionPortal } from "gatsby-plugin-transition-link"
import gsap from "gsap"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

// Style
import layoutStyle from "../style/layouts/wrapper.module.scss"
import heroStyle from "../style/section/projectpage/hero.module.scss"

// Transition Link Component
export const ListTLink = props => (
  <TransitionLink
    style={props.style}
    to={props.to}
    entry={props.entry}
    exit={props.exit}
    trigger={props.trigger}
    activeClassName="focus"
  >
    {props.children}
  </TransitionLink>
)

// new content to be faded in after animation
// export function newContent(node) {
//   return gsap.from(node.querySelectorAll("#layoutWrapper"), {
//     opacity: 0,
//     delay: 1,
//     duration: 0.5,
//   })
// }

// animate image from original size to hero banner
export function animateHero(exit, entry, fromEl, toEl, imageUrl) {
  // From element
  const FromEl = fromEl.current
  const FromElBind = fromEl.current.getBoundingClientRect()
  const morphFromEl = {
    width: FromEl.clientWidth,
    height: FromEl.clientHeight,
    y: FromElBind.top,
    x: FromElBind.left,
  }
  // To element
  const ToEl = entry.node.querySelector(toEl)
  const ToElBind = ToEl.getBoundingClientRect()
  const finalMeasurements = {
    height: ToEl.clientHeight,
    width: ToEl.clientWidth,
    y: ToElBind.top + window.scrollY,
    x: ToElBind.left,
  }

  const root = document.getElementById("blockImageRoot")

  // Clone element
  const clonedElement = FromEl.cloneNode(true)
  clonedElement.setAttribute("id", "blockImageTMP")
  // Append cloned element
  root.appendChild(clonedElement)
  document.body.classList.add("overflow-hidden")

  // Remove cloned element
  setTimeout(function () {
    root.removeChild(clonedElement)
  }, 1750)
  setTimeout(function () {
    document.body.classList.remove("overflow-hidden")
  }, 3000)

  gsap
    .timeline()
    .set("#blockImageTMP", {
      width: morphFromEl.width,
      height: morphFromEl.height,
      x: morphFromEl.x,
      y: morphFromEl.y,
      border: "none",
    })
    .to(exit.node, { opacity: 0, duration: 0.2 })
    .to("#blockImageTMP", {
      width: finalMeasurements.width,
      height: finalMeasurements.height,
      maxHeight: finalMeasurements.height,
      x: finalMeasurements.x,
      y: 64,
      duration: 1.4,
      ease: "power4.out",
    })
}

const LayoutWrapper = props => {
  return pug`
    div#layoutWrapper(className=classnames(layoutStyle.layoutWrapper , props.className))
      ${props.children}
      TransitionPortal
        div#blockImageRoot
  `
}
export default LayoutWrapper
