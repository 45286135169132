// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playground-js": () => import("./../../../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */),
  "component---src-pages-projects-ruten-design-system-js": () => import("./../../../src/pages/projects/ruten-design-system.js" /* webpackChunkName: "component---src-pages-projects-ruten-design-system-js" */),
  "component---src-pages-projects-ruten-messenger-js": () => import("./../../../src/pages/projects/ruten-messenger.js" /* webpackChunkName: "component---src-pages-projects-ruten-messenger-js" */),
  "component---src-pages-projects-space-center-js": () => import("./../../../src/pages/projects/space-center.js" /* webpackChunkName: "component---src-pages-projects-space-center-js" */),
  "component---src-pages-projects-ux-club-js": () => import("./../../../src/pages/projects/ux-club.js" /* webpackChunkName: "component---src-pages-projects-ux-club-js" */),
  "component---src-pages-templates-headline-js": () => import("./../../../src/pages/templates/headline.js" /* webpackChunkName: "component---src-pages-templates-headline-js" */),
  "component---src-pages-templates-test-js": () => import("./../../../src/pages/templates/test.js" /* webpackChunkName: "component---src-pages-templates-test-js" */)
}

